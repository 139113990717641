<!--
 * @Description: 数据字典列表
 * @Author: 小广
 * @Date: 2020-05-06 09:28:38
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-06 16:13:05
 -->
<template>
  <div class="DataDictionaryList">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input :width="width" v-model="searchParams.typeName" label="数据字典类型" />
        <v-input :width="width" v-model="searchParams.typeValue" label="code" />
      </template>
      <template #operateSlot="scope">
        <v-button type="text" text="配置选项" permission="setting" @click="update(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { queryListURL } from './api'

export default {
  name: 'DataDictionaryList',
  data () {
    return {
      width: 180,
      searchUrl: queryListURL,
      searchParams: {
        name: '',
        code: ''
      },
      headers: [
        {
          prop: 'typeName',
          label: '数据字典类型'
        },
        {
          prop: 'typeValue',
          label: 'code'
        },
        {
          prop: 'purpose',
          label: '描述'
        }
      ]
    }
  },
  methods: {
    update (row) {
      this.$router.push({
        name: 'dataDictionaryOptionList',
        query: {
          codeWordTypeId: row.id,
          typeName: row.typeName
        }
      })
    }
  }
}
</script>
