var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DataDictionaryList" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { width: _vm.width, label: "数据字典类型" },
                  model: {
                    value: _vm.searchParams.typeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "typeName", $$v)
                    },
                    expression: "searchParams.typeName",
                  },
                }),
                _c("v-input", {
                  attrs: { width: _vm.width, label: "code" },
                  model: {
                    value: _vm.searchParams.typeValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "typeValue", $$v)
                    },
                    expression: "searchParams.typeValue",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    type: "text",
                    text: "配置选项",
                    permission: "setting",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.update(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }